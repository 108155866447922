<template>
  <div class="container-fluid">
    <div class="card-body">
      <div class="row">
        <div class="col-12 text-center">
          <h3><b>QR KOD ( {{data.instanceName}} )</b></h3>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-sm-6">
          <div class="form-group">
            <template v-if="data && data.downloadReport">
              <div class="form-group">
                <button class="btn btn-info" @click="downloadEvlReport" v-if="qrcode && qrcode.id" :disabled="isPdfLoading">
                  <i class="fa fa-file-pdf-o"></i>
                  <span>Хисоботни юклаб олиш</span>
                  <loading v-if="isPdfLoading"></loading>
                </button>
              </div>
            </template>
          </div>
          <div class="form-group">
            <label>Буюртмачи: <span>{{ qrcode.clientName }}</span></label>
          </div>
          <div class="form-group">
            <label>Сана: <span>{{ qrcode.date }}</span></label>
          </div>
          <div class="form-group">
            <label>Руйхатга олиган ракам: <span>{{ qrcode.registrationObjectNumber }}</span></label>
          </div>
          <div class="form-group" v-if="$route.query.pages">
            <label>Варағлар сони: <span>{{ $route.query.pages }}</span></label>
          </div>
          <div class="form-group">
            <label>Гаров ID:
              <span>{{ qrcode.pledgeId }}</span></label>
          </div>
          <div class="form-group">
            <label>Кредит ID:
              <span>{{ qrcode.creditId }}</span></label>
          </div>
          <div class="form-group">
            <label>Масъул шахс Ф.И.О: <span v-if="qrcode.specialistName">{{ qrcode.specialistName }}</span></label>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group" v-if="qrcode.espData != null">
            <div class="row" style="margin-bottom: 10px">
              <div class="col-md-4">
                <label>Масъул шахс имзоси (ЭЦП): <span>{{ qrcode.espData }}</span></label>
              </div>
              <div class="col-md-3">
                <button class="btn btn-info" @click="downloadSpecialistEsp" :disabled="isAppraiserReportLoading">
                  <i class="fa fa-download"></i>
                  <span>Масъул шахс ЭЦП Юклаш</span>
                  <loading v-if="isAppraiserReportLoading"></loading>
                </button>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>Директор Ф.И.О: <span>Артиқов Рашид Ҳўсинович</span></label>
<!--            <label>Директор Ф.И.О: <span>{{ qrcode.directorName }}</span></label>-->
          </div>
<!--          <div class="form-group" v-if="qrcode.directorReportEsp != null">-->
<!--            <div class="row">-->
<!--              <div class="col-md-4">-->
<!--                <label>Директор имзоси(ЭЦП): <span>{{ qrcode.directorReportEsp }}</span></label>-->
<!--              </div>-->
<!--              <div class="col-md-3">-->
<!--                <button class="btn btn-info" @click="downloadDirectorEsp" :disabled="isDirectorReportLoading">-->
<!--                  <i class="fa fa-download"></i>-->
<!--                  <span>Директор ЭЦП Юклаш</span>-->
<!--                  <loading v-if="isDirectorReportLoading"></loading>-->
<!--                </button>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loading from "../../components/common/Loading"
import {formatDateUnderscore} from "@/shared/utils";
import axios from "axios";

export default {
  name: "e-monitoring",
  props: {
    data: Object
  },
  components: {
    Loading
  },
  data() {
    return {
      qrcode: {
        downloadReport: false
      },
      isAppraiserReportLoading: false,
      isDirectorReportLoading: false,
      isPdfLoading: false
    }
  },
  methods: {
    init() {
      axios.get(this.data.backendUrlApi + '/api/common/qrcode/details/' + this.$props.data.uuid)
          .then((res) => {
            if (res.data){
              this.qrcode = res.data;
            }
          }, () => {
            console.log('error');
          });
    },
    downloadSpecialistEsp() {
      this.isAppraiserReportLoading = true;
      this.$http.get(this.data.backendUrlApi + '/api/download/esp/specialist/' + this.qrcode.id, {responseType: 'arraybuffer'})
          .then(response => {
            let blob = new Blob([response.data], {type: 'application/text'});
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = 'esp' + formatDateUnderscore(new Date()) + '.txt';
            link.click();
            this.isAppraiserReportLoading = false
          })
          .catch(err => {
            console.log(err);
            this.isAppraiserReportLoading = false;
          });

    },
    downloadDirectorEsp() {
      this.isDirectorReportLoading = true;

      this.$http.get(this.data.backendUrlApi + '/api/pledge/director-and-Specialist/esp-info/pdf/DIRECTOR_REPORT/' + this.qrcode.id, {responseType: 'arraybuffer'})
          .then(response => {
            let blob = new Blob([response.data], {type: 'application/pdf'});
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = 'DirectorEspInfo' + formatDateUnderscore(new Date()) + '.pdf';
            link.click();
            this.isDirectorReportLoading = false
          })
          .catch(err => {
            console.log(err);
            this.isDirectorReportLoading = false;
          });
    },
    downloadEvlReport() {
      const vm = this;
      this.isPdfLoading = true;
      const id = this.qrcode.id;

      let url = this.data.backendUrlApi;
      if (this.data.expertiseId === null) {
        url = url + "/api/expertise-item/report/pdf/" + id + "/WITH_QR_CODE";
      } else {
        url = url + "/api/pledge/expertise-item/report/pdf/" + id + "/" + this.data.expertiseId + "/WITH_QR_CODE";
      }

      if (url){
        axios.get(url, {responseType: 'arraybuffer'}).then(response => {
          let blob = new Blob([response.data], {type: 'application/pdf'});
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = 'Хисобот_' + vm.qrcode.clientName + '.pdf';
          link.click();
          this.isPdfLoading = false
        })
            .catch(err => {
              console.log(err);
              this.isPdfLoading = false;
            });
      }
    }
  },
  created() {
    this.init();
  }
}
</script>

<style scoped>

</style>