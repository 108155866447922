export function formatDate(date) {
    if (date) {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        const day_val = (day < 10 ? ('0' + day) : '' + day);
        const month_val = (month < 10 ? ('0' + month) : '' + month);

        return day_val + '/' + month_val + '/' + year;
    }
    return null;
}

export function formatDateTime(date) {
    if (date) {
        const minute = date.getMinutes();
        const hour = date.getHours();
        const day = date.getDate();
        const month = date.getMonth() + 1;

        const minute_val = (minute < 10 ? ('0' + minute) : '' + minute);
        const hour_val = (hour < 10 ? ('0' + hour) : '' + hour);
        const day_val = (day < 10 ? ('0' + day) : '' + day);
        const month_val = (month < 10 ? ('0' + month) : '' + month);

        return day_val + '-' + month_val + '-' + date.getFullYear() + ' ' + hour_val + ':' + minute_val;
    }
    return null;
}

export function parseDate(date_val) {
    if (date_val && date_val.length > 0) {
        const data = date_val.split('/');
        if (data && data.length === 3) {
            return new Date(data[2], data[1] - 1, data[0]);
        }
    }
    return null;
}

export function formatDateUnderscore(date) {
    if (date) {
        const sec = date.getSeconds();
        const mi = date.getMinutes();
        const hh = date.getHours();
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        const day_val = (day < 10 ? ('0' + day) : '' + day);
        const month_val = (month < 10 ? ('0' + month) : '' + month);

        return day_val + '_' + month_val + '_' + year + '_' + hh + '_' + mi + '_' + sec;
    }
    return null;
}