<template>
  <div class="m-2">
    <evaluation v-if="data && data.appServiceType === 'E_BAHOLASH'" :data="data"/>
    <e-xarid v-if="data && data.appServiceType === 'E_XARID'" :data="data"/>
    <e-monitoring v-if="data && data.appServiceType === 'E_MONITORING'" :data="data"/>
  </div>
</template>

<script>
import Evaluation from "@/components/qr-code/evaluation";
import EXarid from "@/components/qr-code/e-xarid";
import EMonitoring from "@/components/qr-code/e-monitoring";
import axios from "axios";
export default {
  name: "index",
  components: {
    EXarid,
    Evaluation,
    EMonitoring
  },
  data() {
    return {
      data: {
        appServiceType : ''
      }
    }
  },
  methods: {
    init() {
      axios.create({
            baseURL: process.env.VUE_APP_API_URL
          }
      ).get('/qr-code/url-param-decode/' + this.$route.params.code).then(res => {
        this.data = res.data
      }, err => {
        console.log(err)
      })
    }
  },
  created() {
    this.init()
  }
}
</script>

<style>
#app{
  text-align: justify;
}
</style>