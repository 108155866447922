<template>
    <div class="timer-parent">
      <div class="loader" :class="remainingPathColor">
        <b class="timer-text">{{formattedTime}}</b>
      </div>
    </div>
</template>

<script>
export default {
  name: "Loading",
  data() {
    return {
      timePassed: 0
    };
  },

  computed: {
    formattedTime() {
      const time = this.timePassed;
      const minutes = Math.floor(time / 60);
      let seconds = time % 60;

      if (seconds < 10) {
        seconds = `0${seconds}`;
      }

      return `${minutes}:${seconds}`;
    },
    remainingPathColor() {
      if (this.timePassed < 10) {
        return "green";
      } else if (this.timePassed < 20) {
        return "light-green";
      } else if (this.timePassed < 30) {
        return "orange";
      } else if (this.timePassed < 40) {
        return "light-orange";
      } else if (this.timePassed < 60) {
        return "red";
      } else {
        return "dark-red";
      }
    }
  },

  mounted() {
    this.startTimer();
  },

  methods: {
    startTimer() {
      setInterval(() => (this.timePassed += 1), 1000);
    }
  }
};
</script>

<style>
.timer-text {
  position: absolute;
  margin: 20px -16px;
}

.timer-parent{
  display:flex;
  justify-content:center;
  align-items:center;
}
</style>