<template>
  <div class="container-fluid">
    <div class="card-body">
      <div class="row">
        <div class="col-12 text-center">
          <h3><b>QR KOD ( e-xarid.uz )</b></h3>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-sm-6">
          <div class="form-group">
            <template v-if="data && data.downloadReport">
              <div class="form-group">
                <button class="btn btn-info" @click="downloadReport" v-if="qrcode && qrcode.id" :disabled="isPdfLoading">
                  <i class="fa fa-file-pdf-o"></i>
                  <span>Хисоботни юклаб олиш</span>
                  <loading v-if="isPdfLoading"></loading>
                </button>
              </div>
            </template>
          </div>
          <template v-if="qrcode.purchaseAndSale">
            <div class="form-group">
              <label>Сотувчи: <span>{{ qrcode.purchaseAndSale.seller }}</span></label>
            </div>
            <div class="form-group">
              <label>Харидор: <span>{{ qrcode.purchaseAndSale.buyer }}</span></label>
            </div>
            <div class="form-group">
              <label>Сана: <span>{{ qrcode.confirmedDate }}</span></label>
            </div>
            <div class="form-group">
              <label>Руйхатга олиган ракам: <span>{{ qrcode.purchaseAndSale.contractNumber }}</span></label>
            </div>
            <div class="form-group">
              <label>Олди сотди қилинган мулк нархи:
                <span>{{qrcode.purchaseAndSale.contractAmount | currency('', 0, { thousandsSeparator: ' ' })}} сўм</span>
              </label>
            </div>
            <div class="form-group">
              <label>Варағлар сони: <span>{{ qrcode.pdfNumberOfPages }}</span></label>
            </div>
            <div class="form-group">
              <label>Муттахсис Ф.И.О: <span>{{ qrcode.specialistName }}</span></label>
            </div>
            <div class="form-group">
              <label>Савдо ташкилоти: <span>{{ qrcode.companyName }}</span></label>
            </div>
          </template>
          <template v-else>
            <div class="form-group">
              <h4>{{ qrcode.companyName }}</h4>
            </div>
            <div class="form-group">
              <label>Буюртмачи: <span>{{ qrcode.clientName }}</span></label>
            </div>
            <div class="form-group">
              <label>Сана: <span>{{ qrcode.confirmedDate }}</span></label>
            </div>
            <div class="form-group">
              <label>Руйхатга олиган ракам: <span>{{ qrcode.id }}</span></label>
            </div>
            <div class="form-group">
              <label>Варағлар сони: <span>{{ qrcode.pdfNumberOfPages }}</span></label>
            </div>
            <div class="form-group">
              <label>Муттахсис Ф.И.О: <span>{{ qrcode.specialistName }}</span></label>
            </div>
          </template>
        </div>
        <div class="col-sm-6">
          <div class="form-group" v-if="qrcode.specialistReportEsp != null">
            <div class="row" style="margin-bottom: 10px">
              <div class="col-md-4">
                <label>Муттахсис имзоси (ЭЦП): <span>{{ qrcode.specialistReportEsp }}</span></label>
              </div>
              <div class="col-md-3">
                <button class="btn btn-info" @click="downloadEsp('specialist', qrcode.uuid)" :disabled="isAppraiserReportLoading">
                  <i class="fa fa-download"></i>
                  <span>Муттахсис ЭЦП Юклаш</span>
                  <loading v-if="isAppraiserReportLoading"></loading>
                </button>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>Директор Ф.И.О: <span>{{ qrcode.directorName }}</span></label>
          </div>
          <div class="form-group" v-if="qrcode.directorReportEsp != null">
            <div class="row">
              <div class="col-md-4">
                <label>Директор имзоси(ЭЦП): <span>{{ qrcode.directorReportEsp }}</span></label>
              </div>
              <div class="col-md-3">
                <button class="btn btn-info" @click="downloadEsp('director', qrcode.uuid)" :disabled="isDirectorReportLoading">
                  <i class="fa fa-download"></i>
                  <span>Директор ЭЦП Юклаш</span>
                  <loading v-if="isDirectorReportLoading"></loading>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Loading from "../../components/common/Loading"

export default {
  name: "e-xarid",
  props: {
    data: Object
  },
  components: {
    Loading
  },
  data() {
    return {
      qrcode: {
        downloadReport: false,
        specialistName: '',
        pdfNumberOfPages: '',
        confirmedDate: '',
        clientName: '',
        directorName: '',
        specialistReportEsp: null,
        directorReportEsp: null,
        purchaseAndSale: null
      },
      isAppraiserReportLoading: false,
      isDirectorReportLoading: false,
      isPdfLoading: false
    }
  },
  methods: {
    init() {
      this.$http.post(this.data.backendUrlApi + '/api/order/check-report',{'uuid' : this.$props.data.uuid})
          .then((res) => {
            if (res.data.status === 'SUCCESS'){
              this.qrcode = res.data.data
            } else {
              this.$swal({title: 'fail', text: res.data.message, type: 'warning'})
            }
          }, () => {
            console.log('error');
          });
    },
    downloadEsp(type, uuid) {
      this.isPdfLoading = true
      this.$http.get(this.data.backendUrlApi + '/api/file/download/esp/' + type + '/' + uuid, {responseType: 'arraybuffer'})
          .then(response => {
            let blob = new Blob([response.data], {type: 'application/text'});
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = 'esp.txt';
            link.click();
            this.isPdfLoading = false
          })
          .catch(err => {
            console.log(err)
            this.isPdfLoading = false
          })
    },
    downloadReport() {
      const vm = this;
      this.isPdfLoading = true;

      if (this.qrcode.report && this.qrcode.report.length > 0){
        this.$http.get(this.qrcode.report[0].filePath, {responseType: 'arraybuffer'}).then(response => {
          let blob = new Blob([response.data], {type: 'application/pdf'});
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = 'Хисобот_' + vm.qrcode.clientName + '.pdf';
          link.click();
          this.isPdfLoading = false
        })
            .catch(err => {
              console.log(err);
              this.isPdfLoading = false;
            });
      }
    }
  },
  created() {
    this.init();
  }
}
</script>

<style scoped>

</style>