<template>
  <div class="container-fluid">
    <div class="card-body">
      <div class="row">
        <div class="col-12 text-center">
          <h3><b>QR KOD ( {{data.instanceName}} )</b></h3>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-sm-6">
          <div class="form-group">
            <template v-if="data && data.downloadReport">
              <div class="form-group">
                <button class="btn btn-info" @click="downloadEvlReport" v-if="qrcode && qrcode.id" :disabled="isPdfLoading">
                  <i class="fa fa-file-pdf-o"></i>
                  <span>Хисоботни юклаб олиш</span>
                  <loading v-if="isPdfLoading"></loading>
                </button>
              </div>
            </template>
          </div>
          <div class="form-group">
            <h4>{{ qrcode.companyName }}</h4>
          </div>
          <div class="form-group">
            <label>Буюртмачи: <span>{{ qrcode.clientName }}</span></label>
          </div>
          <div class="form-group">
            <label>Сана: <span>{{ qrcode.date }}</span></label>
          </div>
          <div class="form-group">
            <label>Руйхатга олиган ракам: <span>{{ qrcode.evaluationObjectNumber }}</span></label>
          </div>
          <div class="form-group" v-if="$route.query.pages">
            <label>Варағлар сони: <span>{{ $route.query.pages }}</span></label>
          </div>
          <template v-if="qrcode.evlObjectType==='Expert2'">
            <div class="form-group">
              <label>"Elektron baholash" МЧЖ қиймати:
                <span>{{ qrcode.evlPrice | currency('', 2, {thousandsSeparator: ' '}) }}</span></label>
            </div>
            <div class="form-group">
              <label>
                Мустақил бахоловчи қиймати:
                <span>{{ qrcode.expert2Price | currency('', 2, {thousandsSeparator: ' '}) }}</span>
              </label>
            </div>
          </template>
          <div v-else class="form-group">
            <label>Умумий баҳоси:
              <span>{{ qrcode.evlPrice | currency('', 2, {thousandsSeparator: ' '}) }}</span></label>
          </div>
          <div class="form-group" v-if="qrcode.appraiserName">
            <label>Баҳоловчи Ф.И.О: <span>{{ qrcode.appraiserName }}</span></label>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group" v-if="qrcode.appraiserReportEsp != null">
            <div class="row" style="margin-bottom: 10px">
              <div class="col-md-4">
                <label>Баҳоловчи имзоси (ЭЦП): <span>{{ qrcode.appraiserReportEsp }}</span></label>
              </div>
              <div class="col-md-3">
                <button class="btn btn-info" @click="downloadAppraiserEsp" :disabled="isAppraiserReportLoading">
                  <i class="fa fa-download"></i>
                  <span>Баҳоловчи ЭЦП Юклаш</span>
                  <loading v-if="isAppraiserReportLoading"></loading>
                </button>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>Директор Ф.И.О: <span>{{ qrcode.directorName }}</span></label>
          </div>
          <div class="form-group" v-if="qrcode.directorReportEsp != null">
            <div class="row">
              <div class="col-md-4">
                <label>Директор имзоси(ЭЦП): <span>{{ qrcode.directorReportEsp }}</span></label>
              </div>
              <div class="col-md-3">
                <button class="btn btn-info" @click="downloadDirectorEsp" :disabled="isDirectorReportLoading">
                  <i class="fa fa-download"></i>
                  <span>Директор ЭЦП Юклаш</span>
                  <loading v-if="isDirectorReportLoading"></loading>
                </button>
              </div>
            </div>
          </div>
          <div class="form-group" v-if="qrcode.espData != null">
            <label>Буюртмачининг имзоси (ЭЦП): <span>{{ qrcode.espData }}</span></label>
          </div>
        </div>
      </div>
      <div class="row">

        <div class="col-sm-12">
          <template v-if="qrcode.evlObjectType==='Auto'">
            <table class="table mdl-data-table">
              <thead>
              <tr>
                <th>№</th>
                <th>Автотранспорт номи</th>
                <th>Давлат рақами</th>
                <th>Баҳоси</th>
              </tr>
              </thead>
              <tbody v-if="qrcode && qrcode.items">
              <tr v-for="(item, index) in qrcode.items" :key="item.id">
                <td>{{ index + 1 }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.governmentNum }}</td>
                <td>{{ item.totalGovPrice }}</td>
              </tr>
              </tbody>
            </table>
          </template>
          <template v-else-if="qrcode.evlObjectType==='Fitment'">
            <table class="table mdl-data-table">
              <thead>
              <tr>
                <th>№</th>
                <th>Бренди</th>
                <th>Асосий восита номи</th>
                <th>Модели</th>
                <th>Баҳоси</th>
              </tr>
              </thead>
              <tbody v-if="qrcode && qrcode.items">
              <tr v-for="(item, index) in qrcode.items" :key="item.id">
                <td>{{ index + 1 }}</td>
                <td>{{ item.name }}</td>
                <td>
                <span v-if="item.brand != null">
                {{ item.brand }}
                </span>
                </td>
                <td>
                <span v-if="item.model != null">
                    {{ item.model }}
                </span>
                </td>
                <td>{{ item.totalGovPrice }}</td>
              </tr>
              </tbody>
            </table>
          </template>
          <template v-else-if="qrcode.evlObjectType==='Building'">
            <table class="table mdl-data-table">
              <thead>
              <tr>
                <th>№</th>
                <th>Кўчмас мулк номи</th>
                <th>Размер (m3)</th>
                <th>Қурулиш ости майдони(m2)</th>
                <th>Баҳоси</th>
              </tr>
              </thead>
              <tbody v-if="qrcode && qrcode.items">
              <tr v-for="(item, index) in qrcode.items" :key="item.id">
                <td>{{ index + 1 }}</td>
                <td>{{ item.name }}</td>
                <td>
                <span v-if="item.volume!=null">
                  {{ item.volume }}
                </span>
                </td>
                <td>
                 <span v-if="item.area!=null">
                   {{ item.area }}
                 </span>
                </td>
                <td>{{ item.totalGovPrice | currency('', 2, {thousandsSeparator: ' '}) }}</td>
              </tr>
              </tbody>
            </table>
          </template>
          <template v-else-if="qrcode.evlObjectType==='Asset'">
            <table class="table mdl-data-table">
              <thead>
              <tr>
                <th>№</th>
                <th>Аралаш мол-мулк номи</th>
                <th>Жойи</th>
                <th>Баҳоси</th>
              </tr>
              </thead>
              <tbody v-if="qrcode && qrcode.items">
              <tr v-for="(item, index) in qrcode.items" :key="item.id">
                <td>{{ index + 1 }}</td>
                <td>{{ item.name }}</td>
                <td>
                <span v-if="item.address != null">
                    {{ item.address }}
                </span>
                </td>
                <td>{{ item.totalGovPrice }}</td>
              </tr>
              </tbody>
            </table>

          </template>
          <template v-else-if="qrcode.evlObjectType==='Expert2'">
            <table class="table mdl-data-table">
              <thead>
              <tr>
                <th>№</th>
                <th>Номи</th>
                <th>Манзили</th>
              </tr>
              </thead>
              <tbody v-if="qrcode && qrcode.items">
              <tr v-for="(item, index) in qrcode.items" :key="item.id">
                <td>{{ index + 1 }}</td>
                <td>{{ item.name }}</td>
                <td>
                <span v-if="item.address != null">
                    {{ item.address }}
                </span>
                </td>
              </tr>
              </tbody>
            </table>
          </template>
          <template v-else>
            <h2>QrCode буйича маълумот топилмади!!!</h2>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../../components/common/Loading"
import {formatDateUnderscore} from "@/shared/utils";

export default {
  name: "evaluation",
  props: {
    data: Object
  },
  components: {
    Loading
  },
  data() {
    return {
      qrcode: {
        downloadReport: false
      },
      isAppraiserReportLoading: false,
      isDirectorReportLoading: false,
      isPdfLoading: false
    }
  },
  methods: {
    init() {
      this.$http.get(this.data.backendUrlApi + '/api/qrcode/details/' + this.$props.data.uuid)
          .then((res) => {
            this.qrcode = res.data;
          }, () => {
            console.log('error');
          });
    },
    downloadAppraiserEsp() {
      this.isAppraiserReportLoading = true;
      this.$http.get(this.data.backendUrlApi + '/api/director-and-appraiser/esp-info/pdf/APPRAISER_SIGN_REPORT/' + this.qrcode.evlObjectType + '/' + this.qrcode.id, {responseType: 'arraybuffer'})
          .then(response => {
            let blob = new Blob([response.data], {type: 'application/pdf'});
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = 'AppraiserEspInfo' + formatDateUnderscore(new Date()) + '.pdf';
            link.click();
            this.isAppraiserReportLoading = false
          })
          .catch(err => {
            console.log(err);
            this.isAppraiserReportLoading = false;
          });

    },
    downloadDirectorEsp() {
      this.isDirectorReportLoading = true;

      this.$http.get(this.data.backendUrlApi + '/api/director-and-appraiser/esp-info/pdf/DIRECTOR_SIGN_REPORT/' + this.qrcode.evlObjectType + '/' + this.qrcode.id, {responseType: 'arraybuffer'})
          .then(response => {
            let blob = new Blob([response.data], {type: 'application/pdf'});
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = 'DirectorEspInfo' + formatDateUnderscore(new Date()) + '.pdf';
            link.click();
            this.isDirectorReportLoading = false
          })
          .catch(err => {
            console.log(err);
            this.isDirectorReportLoading = false;
          });
    },
    downloadEvlReport() {
      const vm = this;
      this.isPdfLoading = true;
      const id = this.qrcode.id;
      const evlObjectType = this.qrcode.evlObjectType;

      let url = this.data.backendUrlApi;
      if (evlObjectType === 'Auto') {
        url += '/api/autoReport/pdf/WITH_QR_CODE/' + id;
      } else if (evlObjectType === 'Fitment') {
        url += '/api/fitmentReport/pdf/' + id + '/WITH_QR_CODE';
      } else if (evlObjectType === 'Building') {
        url += "/api/realEstateReport/pdf/" + id + '/WITH_QR_CODE';
      } else if (evlObjectType === 'Asset') {
        url += '/api/asset/assetReport/pdf/' + id + '/WITH_QR_CODE';
      } else {
        url = null;
      }

      if (url){
        this.$http.get(url, {responseType: 'arraybuffer'}).then(response => {
          let blob = new Blob([response.data], {type: 'application/pdf'});
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = 'Хисобот_' + vm.qrcode.clientName + '.pdf';
          link.click();
          this.isPdfLoading = false
        })
            .catch(err => {
              console.log(err);
              this.isPdfLoading = false;
            });
      }
    }
  },
  created() {
    this.init();
  }
}
</script>

<style scoped>

</style>